import { graphql, useStaticQuery } from "gatsby"

export const useDataSecurityData = () => {
  return useStaticQuery(graphql`
    {
      prismicDataSecurityAndPrivacy {
        data {
          safeguardlogo {
            alt
            gatsbyImageData
            url
          }
          title {
            text
            richText
            html
            raw
          }
          subtitle {
            html
            raw
            richText
            text
          }
          security_features {
            desc
            name
            icon {
              alt
              gatsbyImageData
              url
            }
          }
          feathers_heading {
            html
            raw
            richText
            text
          }
          enterpriseheader {
            name
            description
            icon {
              alt
              gatsbyImageData
              url
            }
          }
          enterprise_header {
            html
            raw
            richText
            text
          }
          commitment_security_header {
            html
            raw
            richText
            text
          }
          commitement_security {
            name
            icon {
              alt
              gatsbyImageData
              url
            }
            description {
              html
              text
              raw
              richText
            }
          }
        }
      }
    }
  `)?.prismicDataSecurityAndPrivacy?.data
}
