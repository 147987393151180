import React from "react"
import "./SecurityFeatureBox.scss"
import { GatsbyImage } from "gatsby-plugin-image"

interface SecurityFeatureBox {
  name?: string
  icon?: any
  desc?: any
  isPowered?: boolean
}

const SecurityFeatureBox: React.FC<SecurityFeatureBox> = ({
  name,
  icon,
  desc,
  isPowered,
}) => {
  return (
    <div className="SecurityFeatureBox">
      {!isPowered && (
        <div className="image">
          <GatsbyImage alt={icon.alt} image={icon.gatsbyImageData} />
        </div>
      )}
      <div className="desc-container">
        <div className="name">{name}</div>
        <div className="desc">{desc}</div>
      </div>
      {isPowered && (
        <div className="powered-by">
          <div>Powered by</div>
          <GatsbyImage alt={icon.alt} image={icon.gatsbyImageData} />
        </div>
      )}
    </div>
  )
}

export default SecurityFeatureBox
