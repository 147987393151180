import { Layout, SEO } from "components"
import { Link } from "gatsby"
import { useDataSecurityData } from "hooks/useDataSecurityData"
import * as React from "react"
import "./security-features.scss"
import SecurityFeatureBox from "components/SecurityFeatureBox/SecurityFeatureBox"
import { GatsbyImage } from "gatsby-plugin-image"

interface ISecurityFeaturesProps {}

const SecurityFeatures: React.FunctionComponent<
  ISecurityFeaturesProps
> = () => {
  const data = useDataSecurityData()
  const {
    title = {},
    subtitle = {},
    security_features = [],
    feathers_heading = {},
    enterpriseheader = [],
    enterprise_header = {},
    commitment_security_header = {},
    commitement_security = [],
    safeguardlogo = {},
  } = data || {} // Ensures data itself is not undefined/null
  return (
    <Layout>
      <SEO
        title="Data Privacy & Security Features"
        description="Security of your data and your privacy is a business priority at Teamble. We build everything with customer trust and security in mind."
      />
      <div className="SecurityFeatures">
        {title?.text && <div className="header ">{title?.text}</div>}
        {subtitle?.text && <div className="para">{subtitle?.text}</div>}
        {safeguardlogo && (
          <div className="safe-guard">
            <a target="_blank" href="https://security.teamble.com/">
              <GatsbyImage
                class="image-card"
                alt={safeguardlogo?.alt}
                image={safeguardlogo?.gatsbyImageData}
                objectFit="contain"
              />
            </a>
          </div>
        )}
        {feathers_heading?.text && (
          <div className="sub-heading">{feathers_heading?.text}</div>
        )}
        <div className="feature-container">
          {security_features?.map((item: any, index: number) => (
            <SecurityFeatureBox
              key={index}
              name={item?.name}
              desc={item?.desc}
              icon={item?.icon}
            />
          ))}
        </div>
        {feathers_heading?.text && (
          <div className="sub-heading">{enterprise_header?.text}</div>
        )}
        <div className="feature-container">
          {enterpriseheader?.map((item: any, index: number) => (
            <SecurityFeatureBox
              key={index}
              name={item?.name}
              desc={item?.description}
              icon={item?.icon}
              isPowered={
                item?.name?.toLowerCase() ===
                "Security Status Page".toLocaleLowerCase()
              }
            />
          ))}
        </div>
        <div className="privacy-policy-section">
          <div className="privacy-policy-text">
            Please visit our{" "}
            <a href="/privacy" className="privacy-policy-link">
              Privacy Policy
            </a>
            to read more about our commitment to the regional and international
            data privacy regulations.
          </div>
        </div>

        {commitment_security_header?.text && (
          <div className="sub-heading">{commitment_security_header?.text}</div>
        )}
        <div className="feature-container">
          {commitement_security?.map((item: any, index: number) => (
            <SecurityFeatureBox
              key={index}
              name={item?.name}
              desc={item?.description?.text}
              icon={item?.icon}
            />
          ))}
        </div>
        <div className="sub-heading">Responsible Disclosure</div>
        <div className="vulnerability-disclosure-section">
          <div className="vulnerability-disclosure-text">
            If you believe you’ve discovered a potential vulnerability, please
            let us know by emailing us at{" "}
            <a
              href="mailto:security@teamble.com"
              className="vulnerability-email"
            >
              security@teamble.com
            </a>
            . We will acknowledge your email within ten business days.
          </div>

          <div className="vulnerability-resolution">
            Provide us with a reasonable amount of time to resolve the issue
            before disclosing it to the public or a third party. We aim to
            resolve critical issues within one month of disclosure.
          </div>

          <div className="vulnerability-guidelines">
            Make a good faith effort to avoid violating privacy, destroying
            data, or interrupting or degrading the Teamble service. Please only
            interact with accounts you own or for which you have explicit
            permission from the account holder.
          </div>
        </div>
        <div className="sub-heading">Exclusions</div>
        <div className="research-guidelines-section">
          <div className="research-guidelines-intro">
            While researching, we’d like you to refrain from:
          </div>
          <ul className="research-guidelines-list">
            <li className="research-guidelines-item">
              Distributed Denial of Service (DDoS)
            </li>
            <li className="research-guidelines-item">Spamming</li>
            <li className="research-guidelines-item">
              Social engineering or phishing of Teamble employees or contractors
            </li>
            <li className="research-guidelines-item">
              Any attacks against Teamble’s physical property or data centers
            </li>
          </ul>
        </div>
        <div className="security-contact-section">
          <div className="security-contact-header">
            Security questions or issues?
          </div>
          <div className="security-contact-text">
            Please contact us at{" "}
            <a
              href="mailto:security@teamble.com"
              className="security-contact-email"
            >
              security@teamble.com
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SecurityFeatures
